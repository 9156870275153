<!-- eslint-disable radix -->
<!-- eslint-disable no-param-reassign -->
<template>
  <div class=" border-3 box-shadow-3 rounded-bottom">
    <div class="px-1 px-sm-0">
      <b-card>
        <b-row>
          <b-card-title class="mr-2 title">
            CONTRIBUTIONS :
          </b-card-title>
          <div class="ml-2">
            <feather-icon
              class="cursor-pointer"
              :class="isBusy ? 'lds-ring' : ''"
              icon="RefreshCcwIcon"
              size="18"
              @click="reloadPage"
            />
          </div>
        </b-row>
  
        <validation-observer ref="validateContributionData">
          <b-row>
            <b-col
              v-for="(contribution, index) in contributionData"
              :key="index"
              md="6"
            >
              <b-form-group
                label-class="font-bureau-style font-weight-normal color-gray-input-sm"
                label-cols-lg="3"
                label-cols-md="4"
                label-cols-sm="3"
                content-cols-lg="8"
                content-cols-md="7"
                content-cols-sm="9"
              >
                <template #label>
                  <feather-icon
                    icon="SquareIcon"
                    style="margin-right: 0.5rem; fill: #0090e7"
                    class="text-primary"
                  />
                  <span> {{ contribution.name.toUpperCase() }} </span>
                </template>
  
                <validation-provider
                  v-slot="{ errors }"
                  :name="contribution.name + index"
                  :rules="contribution.type == 'percentage' ? 'validate-percentage-min|validate-percentage-max' :'validate-percentage-min'"
                >
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space"
                    type="input"
                  />
                  <b-input-group
                    v-if="finishedLoading"
                    :class="contribution.editing ? 'active-border' : ''"
                    :prepend="contribution.type === 'percentage' ? ' %' : 'S/.'"
                  >
                    <money
                      v-if="contribution.type == 'percentage'"
                      v-model="contribution.value"
                      v-bind="vMoneyPercentage"
                      :class="contribution.editing ? 'color-active' : 'color-disabled'"
                      class="form-control input-form"
                      :disabled="!contribution.editing"
                      @input="changeValue(contribution)"
                    />
  
                    <money
                      v-else
                      v-model="contribution.value"
                      v-bind="vMoneyDollars"
                      :class="contribution.editing ? 'color-active' : 'color-disabled'"
                      class="form-control input-form"
                      :disabled="!contribution.editing"
                      @input="changeValue(contribution)"
                    />
  
                    <b-input-group-append
                      v-if="contribution.editing"
                      class="border-right"
                    >
                      <b-button
                        variant="outline-primary"
                        class="btn-icon"
                        @click="saveContribution(contribution, index)"
                      >
                        <feather-icon
                          icon="SaveIcon"
                          class="cursor-pointer"
                        />
                      </b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button
                        v-if="contribution.count >= 1"
                        v-b-tooltip.hover.top="'Request change is pending'"
                        variant="outline-danger"
                        class="btn-icon"
                      >
                        <feather-icon
                          icon="InfoIcon"
                          class="cursor-pointer"
                        />
                      </b-button>
                      <template v-else>
                        <b-button
                          v-if="!contribution.editing"
                          variant="outline-warning"
                          class="btn-icon"
                          @click="editContribution(index)"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            class="cursor-pointer"
                          />
                        </b-button>
                      </template>
                      <b-button
                        v-if="contribution.editing"
                        variant="outline-danger"
                        class="btn-icon"
                        @click="cancelEditContribution(index)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="cursor-pointer"
                        />
                      </b-button>
                    </b-input-group-append>
                    <b-input-group-append class="cursor-pointer">
                      <b-input-group-text
                        @click="
                          openListTrackingCedContribution(
                            contribution
                          )
                        "
                      >
                        <feather-icon
                          icon="ListIcon"
                          class="text-primary"
                        />
                      </b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
  
                  <span
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ errors[0] }} </span>
                </validation-provider>
  
              </b-form-group>
  
            </b-col>
          </b-row>
  
        </validation-observer>
  
        <list-tracking-contribution
          v-if="openListTrackingContributionOn"
          :id-contribution="idToSend"
          @canceledChange="reloadPage"
          @close="closeListTrackingContribution"
        />
  
      </b-card>
  
      <b-card>
        <b-row>
          <b-card-title class="mr-2 title">
            FIFTH CATEGORY :
          </b-card-title>
          <div class="ml-2">
            <feather-icon
              class="cursor-pointer"
              :class="isBusy ? 'lds-ring' : ''"
              icon="RefreshCcwIcon"
              size="18"
              @click="reloadPage"
            />
          </div>
        </b-row>
  
        <validation-observer ref="validateCategoryData">
          <b-row class="modal-subtitle">
            <b-col
              md="2"
              xxl="1"
            />
  
            <b-col
              md="8"
              xxl="9"
            >
              <b-row>
                <b-col
                  md="6"
                  class="font-weight-normal text-center subtitle"
                >
                  <span>PERCENTAGE</span>
                </b-col>
  
                <b-col
                  md="6"
                  class="font-weight-normal text-center subtitle"
                >
                  <span>LIMIT</span>
                </b-col>
              </b-row>
            </b-col>
  
            <b-col
              md="2"
              xxl="2"
            />
  
          </b-row>
          <b-row>
            <b-col
              v-for="(category, index) in categoryData"
              :key="index"
              md="12"
              class="mt-1"
            >
              <b-row>
                <b-col
                  xxl="1"
                  md="2"
                  sm="12"
                  xs="12"
                >
                  <b-form-group label-class="font-bureau-style font-weight-normal color-gray-input-sm margin-form-group">
                    <template
                      #label
                    >
                      <div class="margin-tramo">
                        <feather-icon
                          icon="SquareIcon"
                          style="margin-right: 0.5rem; fill: #EFA047"
                          class="text-light"
                        />
                        <span> {{ category.name.toUpperCase() }} </span>
                      </div>
                    </template>
  
                  </b-form-group>
                </b-col>
  
                <b-col
                  xxl="9"
                  md="8"
                  sm="8"
                  xs="12"
                >
                  <b-row>
                    <b-col
                      md="6"
                      sm="6"
                      xs="12"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="category.name + index"
                        rules="validate-percentage-min|validate-percentage-max"
                      >
  
                        <b-form-group
                          class="mb-0"
                          :class="category.editing ? 'active-border' : ''"
                        >
  
                          <b-skeleton
                            v-if="!finishedLoading"
                            class="space"
                            type="input"
                          />
  
                          <b-input-group
                            v-if="finishedLoading"
                            prepend="%"
                          >
                            <money
                              v-model="category.percentage"
                              v-bind="vMoneyPercentage"
                              :class="category.editing ? 'color-active' : 'color-disabled'"
                              class="form-control input-form"
                              :disabled="!category.editing"
                            />
  
                          </b-input-group>
                        </b-form-group>
                        <span
                          v-if="errors[0]"
                          class="text-danger"
                        >{{ errors[0] }} </span>
                      </validation-provider>
  
                    </b-col>
  
                    <b-col
                      md="6"
                      sm="6"
                      xs="12"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="category.name + index+1"
                        :rules="category.name != '5to Tramo' ? 'validate-percentage-min' : ''"
                      >
                        <b-form-group
                          class="mb-0"
                          :class="category.editing && category.name != '5to Tramo' ? 'active-border' : ''"
                        >
                          <b-skeleton
                            v-if="!finishedLoading"
                            class="space"
                            type="input"
                          />
                          <b-input-group
                            v-if="finishedLoading"
                            prepend="UIT"
                          >
                            <money
                              v-if="category.name != '5to Tramo' "
                              v-model="category.limit"
                              v-bind="vMoneyLimit"
                              class="form-control input-form"
                              :class="category.editing ? 'color-active' : 'color-disabled'"
                              :disabled="!category.editing"
                            />
                            <input
                              v-else
                              type="text"
                              class="form-control input-form color-disabled"
                              disabled
                              value="-"
                            >
                          </b-input-group>
                        </b-form-group>
                        <span
                          v-if="errors[0]"
                          class="text-danger"
                        >{{ errors[0] }} </span>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
  
                <b-col
                  xxl="2"
                  md="2"
                  xs="12"
                  sm="4"
                >
                  <div class="d-flex justify-content-center margin-xs">
                    <b-button
                      v-if="category.editing"
                      class="btn-icon border btn-sm-style"
                      variant="outline-primary"
                      @click="saveCategory(category, index)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top="'Save'"
                        icon="SaveIcon"
                        size="16"
                        class="text-primary cursor-pointer"
                      /></b-button>
                    <b-button
                      v-if="category.editing"
                      class="btn-icon border btn-sm-style"
                      variant="outline-danger"
                      @click="cancelEditCategory(category, index)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top="'Delete'"
                        icon="TrashIcon"
                        size="16"
                        class="text-danger cursor-pointer"
                      /></b-button>
                    <b-button
                      v-if="!category.editing"
                      class="btn-icon border btn-sm-style"
                      variant="outline-warning"
                      @click="editCategory(category, index)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top="'Edit'"
                        icon="Edit3Icon"
                        size="16"
                        class="text-warning cursor-pointer"
                      /></b-button>
                    <b-button
                      variant="outline-secondary"
                      class="btn-icon border btn-sm-style"
                      @click="openListTrackingCategory(category)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top="'Tracking'"
                        icon="ListIcon"
                        class="text-primary"
                        size="16"
                      />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
  
            </b-col>
  
          </b-row>
  
        </validation-observer>
  
        <list-tracking-category
          v-if="openListTrackingCategoryOn"
          :id-tramo="idToSend"
          @canceledChange="reloadPage"
          @close="closeListTrackingCategory"
        />
  
      </b-card>
  
      <b-card>
        <b-row>
          <b-card-title class="mr-2 title">
            PENSION SYSTEM :
          </b-card-title>
  
          <div class="mr-3">
            <feather-icon
              icon="SquareIcon"
              style="margin-right: 0.5rem; fill: #85EB91"
              class="text-success"
            />
            Private
          </div>
          <div>
            <feather-icon
              icon="SquareIcon"
              style="margin-right: 0.5rem; fill: #9269E8"
            />
            Public
          </div>
  
          <div class="ml-2">
            <feather-icon
              class="cursor-pointer"
              :class="isBusy ? 'lds-ring' : ''"
              icon="RefreshCcwIcon"
              size="18"
              @click="reloadPage"
            />
          </div>
        </b-row>
  
        <validation-observer ref="validatePensionFundData">
          <b-row class="mt-xxl-1 modal-subtitle">
            <b-col
              md="2"
              xxl="1"
            />
            <b-col
              md="8"
              xxl="9"
            >
              <b-row>
                <b-col
                  md="4"
                  sm="4"
                  class="font-weight-normal text-center subtitle"
                >
                  <span>MANDATORY CONTRIBUTION</span>
                </b-col>
                <b-col
                  md="4"
                  sm="4"
                  class="font-weight-normal text-center subtitle"
                >
                  <span>INSURANCE</span>
                </b-col>
                <b-col
                  md="4"
                  sm="4"
                  class="font-weight-normal text-center subtitle"
                >
                  <span>COMMISIONS</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              md="2"
              xxl="2"
            />
          </b-row>
  
          <b-row>
            <b-col
              v-for="(pension, index) in pensionFundData"
              :key="index"
              cols="12"
              class="mt-1"
            >
              <b-row>
                <b-col
                  md="2"
                  sm="12"
                  xs="12"
                  xxl="1"
                >
                  <b-form-group label-clamodelss="font-bureau-style font-weight-normal color-gray-input-sm ">
                    <template #label>
                      <div class="margin-pension">
                        <feather-icon
                          icon="SquareIcon"
                          style="margin-right: 0.5rem"
                          :style="
                            pension.type == 'private'
                              ? 'fill: #85EB91'
                              : 'fill: #9269E8'
                          "
                          :class="
                            pension.type == 'private'
                              ? 'text-success'
                              : ''
                          "
                        />
                        <span> {{ pension.name.toUpperCase() }} </span>
                      </div>
                    </template>
  
                  </b-form-group>
                </b-col>
  
                <b-col
                  xxl="9"
                  md="8"
                  sm="10"
                  xs="12"
                >
                  <b-row>
                    <b-col
                      md="4"
                      sm="4"
                      xs="4"
                    >
  
                      <validation-provider
                        v-slot="{ errors }"
                        :name="pension.name + index"
                        rules="validate-percentage-min|validate-percentage-max"
                      >
  
                        <b-form-group
                          class="mb-0"
                          :class="pension.editing ? 'active-border' : ''"
                        >
                          <b-skeleton
                            v-if="!finishedLoading"
                            class="space"
                            type="input"
                          />
  
                          <b-input-group
                            v-if="finishedLoading"
                            prepend="%"
                          >
                            <money
                              v-model="pension.mandatory_contribution"
                              v-bind="vMoneyPercentage"
                              class="form-control input-form"
                              :class="pension.editing ? 'color-active' : 'color-disabled'"
                              :disabled="!pension.editing"
                            />
                          </b-input-group>
                        </b-form-group>
  
                        <span
                          v-if="errors[0]"
                          class="text-danger"
                        >{{ errors[0] }} </span>
                      </validation-provider>
                    </b-col>
  
                    <b-col
                      md="4"
                      sm="4"
                      xs="4"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="pension.name + index+1"
                        rules="validate-percentage-min|validate-percentage-max"
                      >
  
                        <b-form-group
                          class="mb-0"
                          :class="pension.editing && pension.type != 'public' ? 'active-border' : ''"
                        >
                          <b-skeleton
                            v-if="!finishedLoading"
                            class="space"
                            type="input"
                          />
  
                          <b-input-group
                            v-if="finishedLoading"
                            prepend="%"
                          >
                            <money
                              v-if="pension.type != 'public' "
                              v-model="pension.insurance"
                              v-bind="vMoneyPercentage"
                              class="form-control input-form"
                              :class="pension.editing ? 'color-active' : 'color-disabled'"
                              :disabled="!pension.editing"
                            />
  
                            <input
                              v-else
                              type="text"
                              class="form-control input-form color-disabled"
                              disabled
                              value="-"
                            >
                          </b-input-group>
                        </b-form-group>
                        <span
                          v-if="errors[0]"
                          class="text-danger"
                        >{{ errors[0] }} </span>
                      </validation-provider>
                    </b-col>
  
                    <b-col
                      md="4"
                      sm="4"
                      xs="4"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="pension.name + index+2"
                        rules="validate-percentage-max"
                      >
  
                        <b-form-group
                          class="mb-0"
                          :class="pension.editing && pension.type != 'public' ? 'active-border' : ''"
                        >
                          <b-input-group prepend="%">
                            <money
                              v-if="pension.type != 'public' "
                              v-model="pension.commissions"
                              v-bind="vMoneyPercentage"
                              class="form-control input-form"
                              :class="pension.editing ? 'color-active' : 'color-disabled'"
                              :disabled="!pension.editing"
                            />
                            <input
                              v-else
                              type="text"
                              class="form-control input-form color-disabled"
                              disabled
                              value="-"
                            >
                          </b-input-group>
                        </b-form-group>
                        <span
                          v-if="errors[0]"
                          class="text-danger"
                        >{{ errors[0] }} </span>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
  
                <b-col
                  xxl="2"
                  sm="2"
                  md="2"
                  xs="12"
                >
                  <div class="d-flex justify-content-center margin-xs">
                    <b-button
                      v-if="pension.editing"
                      class="btn-icon border btn-sm-style "
                      variant="outline-primary"
                      @click="savePension(pension, index)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top="'Save'"
                        icon="SaveIcon"
                        size="16"
                        class="text-primary cursor-pointer"
                      /></b-button>
                    <b-button
                      v-if="pension.editing"
                      class="btn-icon border btn-sm-style"
                      variant="outline-danger"
                      @click="cancelEditPension(index)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top="'Delete'"
                        icon="TrashIcon"
                        size="16"
                        class="text-danger cursor-pointer m-0 p-0"
                      /></b-button>
                    <b-button
                      v-if="!pension.editing"
                      class="btn-icon border btn-sm-style"
                      variant="outline-warning"
                      @click="editPension(index)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top="'Edit'"
                        icon="Edit3Icon"
                        size="16"
                        class="text-warning cursor-pointer"
                      /></b-button>
                    <b-button
                      variant="outline-secondary"
                      class="btn-icon border btn-sm-style"
                      @click="openListTrackingPensionFund(pension)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top="'Tracking'"
                        icon="ListIcon"
                        class="text-primary"
                        size="16"
                      />
                    </b-button>
                  </div>
  
                </b-col>
  
              </b-row>
  
            </b-col>
          </b-row>
  
        </validation-observer>
  
        <list-tracking-pension-fund
          v-if="openListTrackingPensionFundOn"
          :id-pension="idToSend"
          this-finished-loading="true@canceledChange=&quot;reloadPage&quot;"
          @close="closeListTrackingPensionFund"
        />
  
      </b-card>
  
    </div>
  </div>
</template>

<script>

import SettingsService from '@/views/commons/setting/service/payment-setting.service';
import ListTrackingContribution from '@/views/commons/setting/components/modals/ListTrackingContribution.vue';

import ListTrackingCategory from '@/views/commons/setting/components/modals/ListTrackingCategory.vue';

import ListTrackingPensionFund from '@/views/commons/setting/components/modals/ListTrackingPensionFund.vue';

import { mapGetters } from 'vuex';

export default {
  components: {
    ListTrackingContribution,
    ListTrackingCategory,
    ListTrackingPensionFund,
  },
  data() {
    return {
      finishedLoading: false,
      idToSend: '',
      contributionData: [],
      categoryData: [],
      pensionFundData: [],
      isBusy: false,
      vMoney: {
        decimal: '.',
        thousand: ',',
        precision: 2,
        min: 0.0,
        maxlength: 10,
        prefix: 'S/.',
      },
      vMoneyLimit: {

        maxlength: 10,
        precision: 0,
      },

      vMoneyDollars: {
        decimal: '.',
        thousand: ',',
        precision: 2,
        min: 0.0,
        maxlength: 10,
      },
      vMoneyPercentage: {
        decimal: '.',
        thousand: ',',
        precision: 2,
        min: 0.0,
        max: 100.00,
        maxlength: 5,
      },
      openListTrackingContributionOn: false,
      openListTrackingCategoryOn: false,
      openListTrackingPensionFundOn: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

  },

  async created() {
    await Promise.all([
      this.getContributionData(),
      this.getSettingFifthCategory(),
      this.getPensionFundSetting(),
    ]);
    this.finishedLoading = true;
  },

  methods: {

    changeValue(contribution) {
      let newValue;
      let indexState;
      // eslint-disable-next-line default-case
      switch (contribution.slug) {
        case 'SCBCP':
          newValue = contribution.value;
          indexState = this.contributionData.findIndex(contribution2 => contribution2.slug === 'SCBSP');
          this.contributionData[indexState].value = 100 - newValue;
          break;

        case 'SCBSP':
          newValue = contribution.value;
          indexState = this.contributionData.findIndex(contribution2 => contribution2.slug === 'SCBCP');
          this.contributionData[indexState].value = 100 - newValue;
          break;

        // case 'SEP':
        //   newValue = contribution.value;
        //   indexState = this.contributionData.findIndex(contribution2 => contribution2.slug === 'BEP');
        //   this.contributionData[indexState].value = 100 - newValue;
        //   break;

        // case 'BEP':
        //   newValue = contribution.value;
        //   indexState = this.contributionData.findIndex(contribution2 => contribution2.slug === 'SEP');
        //   this.contributionData[indexState].value = 100 - newValue;
        //   break;
      }
    },

    closeListTrackingContribution() {
      this.openListTrackingContributionOn = false;
    },
    openListTrackingCedContribution(item) {
      this.idToSend = item.slug;
      this.openListTrackingContributionOn = true;
    },

    editContribution(index) {
      this.contributionData[index].savedData = this.contributionData[index].value;
      this.contributionData[index].editing = true;
    },

    cancelEditContribution(index) {
      this.contributionData[index].value = this.contributionData[index].savedData;
      this.contributionData[index].editing = false;
    },

    closeListTrackingCedCommission() {
      this.openListTrackingCedCommissionsOn = false;
    },

    async saveContributionData(index) {
      const data = await SettingsService.saveChangeContribution({
        id: this.contributionData[index].id,
        name: this.contributionData[index].name,
        type: this.contributionData[index].type,
        new_value: this.contributionData[index].value,
        slug: this.contributionData[index].slug,
        created_by: this.currentUser.user_id,
      });

      return data;
    },
    async saveContribution(contributionData, index) {
      const contributionArray = this.contributionData;
      // console.log(contributionArray)
      try {
        if (contributionArray[index].value !== contributionArray[index].savedData) {
          const resultValidation = await this.$refs.validateContributionData.validate();
          if (resultValidation) {
            const response = await this.showConfirmSwal();
            if (response.isConfirmed) {
              let result; let result1;
              switch (this.contributionData[index].slug) {
                case 'SCBCP':
                case 'SCBSP':
                  result = await this.saveContributionData(2);
                  result1 = await this.saveContributionData(4);

                  if (result.status === 200 && result1.status === 200) {
                    await this.getContributionData();
                    this.showGenericSuccessSwal({});
                  }

                  break;
              //   case 'SEP':
              //   case 'BEP':
              //     result = await this.saveContributionData(1);
              //     result1 = await this.saveContributionData(3);

              //     if (result.status === 200 && result1.status === 200) {
              //       await this.getContributionData();
              //       this.showGenericSuccessSwal({});
              //     }

              //     break;
                default:
                  await this.saveContributionData(index);
                  await this.getContributionData();
                  this.showGenericSuccessSwal({});
                  break;
              }
            }
          }
        } else {
          this.showWarningSwal(
            'Invalid Change',
            'The entered value is the same as the old one',
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    orderContributionData(slug) {
      switch (slug) {
        case 'ECP':
          return 1;
      //   case 'SEP':
      //     return 2;
        case 'SCA':
          return 2;
      //   case 'BEP':
      //     return 4;
        case 'SCBCP':
          return 3;
        case 'UIT':
          return 4;
        default:
          return 5; // SBCSP
      }
    },

    async getContributionData() {
      try {
        let fake = [];
        this.contributionData = [];
        this.isBusy = true;
        const data = await SettingsService.getPaymentSetting();

        if (data.status === 200) {
          fake = data.data;
          fake.forEach(contribution => {
            contribution.editing = false;
            contribution.savedData = 0;
            contribution.value = parseFloat(contribution.value);
            contribution.order = this.orderContributionData(contribution.slug);
          });

          this.contributionData = fake;

          this.contributionData.sort((a, b) => a.order - b.order);
          setTimeout(() => {
            this.isBusy = false;
          }, 900);
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
        this.isBusy = false;
      }
    },

    async reloadPage() {
      await this.getContributionData();
      await this.getPensionFundSetting();
      await this.getSettingFifthCategory();
    },

    // Quinta Categoria

    async getSettingFifthCategory() {
      try {
        let fake = [];
        this.categoryData = [];
        this.isBusy = true;
        const data = await SettingsService.getSettingFifthCategory();

        if (data.status === 200) {
          fake = data.data;
          fake.forEach(category => {
            category.editing = false;
            category.savedDataPercentage = 0;
            category.savedDataLimit = 0;
            category.percentage = parseFloat(category.percentage);
            category.limit = parseInt(category.limit, 10);
          });

          this.categoryData = fake;

          setTimeout(() => {
            this.isBusy = false;
          }, 900);
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
        this.isBusy = false;
      }
    },

    editCategory(category, index) {
      this.categoryData[index].editing = true;
      this.categoryData[index].savedDataPercentage = this.categoryData[index].percentage;
      this.categoryData[index].savedDataLimit = this.categoryData[index].limit;
    },
    cancelEditCategory(category, index) {
      this.categoryData[index].percentage = this.categoryData[index].savedDataPercentage;
      this.categoryData[index].limit = this.categoryData[index].savedDataLimit;
      this.categoryData[index].editing = false;
    },

    async saveCategory(category, index) {
      const categoryArray = this.categoryData;
      try {
        const isFourthTramo = categoryArray[index].name === '4to Tramo';
        const firstTramo = categoryArray[index].name === '1er Tramo' ? categoryArray[index].percentage < categoryArray[index + 1].percentage && categoryArray[index].limit < categoryArray[index + 1].limit : true;
        const fifthTramo = categoryArray[index].name === '5to Tramo' ? categoryArray[index].percentage > categoryArray[index - 1].percentage && categoryArray[index].percentage !== categoryArray[index].savedDataPercentage : true;
        const fifthTramoValidar = categoryArray[index].name === '5to Tramo' ? categoryArray[index].percentage !== categoryArray[index].savedDataPercentage : true;
        const checkPreviousTramo = categoryArray[index].name !== '1er Tramo' && categoryArray[index].name !== '5to Tramo' ? categoryArray[index].percentage > categoryArray[index - 1].percentage && categoryArray[index].limit > categoryArray[index - 1].limit : true;
        const checkNextTramo = categoryArray[index].name !== '1er Tramo' && categoryArray[index].name !== '5to Tramo' ? categoryArray[index].percentage < categoryArray[index + 1].percentage && (isFourthTramo || (categoryArray[index].limit < categoryArray[index + 1].limit)) : true;

        const validarDatos = categoryArray[index].percentage !== categoryArray[index].savedDataPercentage || categoryArray[index].limit !== categoryArray[index].savedDataLimit;

        if (firstTramo && fifthTramo && checkPreviousTramo && checkNextTramo && validarDatos) {
          const resultValidation = await this.$refs.validateCategoryData.validate();
          if (resultValidation) {
            const response = await this.showConfirmSwal();
            if (response.isConfirmed) {
              const data = await SettingsService.saveChangeCategory({
                id: category.id,
                name: category.name,
                percentage: category.percentage,
                limit: category.limit,
                created_by: this.currentUser.user_id,
              });
              if (data.status === 200) {
                await this.getSettingFifthCategory();
                this.showGenericSuccessSwal({});
              }
            }
          }
        } else if (!fifthTramoValidar) {
          this.showWarningSwal(
            'Invalid Change',
            'The entered value is the same as the old one',
          );
        } else if (!checkPreviousTramo || !fifthTramo) {
          this.showWarningSwal(
            'Invalid Change',
            'The entered value cannot be greater than or equal to the value of previous section',
          );
        } else if (!checkNextTramo || !firstTramo) {
          this.showWarningSwal(
            'Invalid Change',
            'The entered value cannot be greater than or equal to the value of next section',
          );
        } else {
          this.showWarningSwal(
            'Invalid Change',
            'The entered value is the same as the old one',
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    closeListTrackingCategory() {
      this.openListTrackingCategoryOn = false;
    },

    openListTrackingCategory(item) {
      this.idToSend = item.name;
      this.openListTrackingCategoryOn = true;
    },

    // PENSION SISTEMS

    async getPensionFundSetting() {
      try {
        let fake = [];
        this.pensionFundData = [];
        this.isBusy = true;
        const data = await SettingsService.getPensionFundSetting();

        if (data.status === 200) {
          fake = data.data;
          fake.forEach(pension => {
            pension.editing = false;
            pension.savedDataContribution = 0;
            pension.savedDataInsurance = 0;
            pension.savedDataCommisions = 0;
            pension.insurance = parseFloat(pension.insurance);
            pension.mandatory_contribution = parseFloat(pension.mandatory_contribution);
            pension.commissions = parseFloat(pension.commissions);
          });

          this.pensionFundData = fake;

          setTimeout(() => {
            this.isBusy = false;
          }, 900);
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
        this.isBusy = false;
      }
    },

    editPension(index) {
      this.pensionFundData[index].editing = true;
      this.pensionFundData[index].savedDataContribution = this.pensionFundData[index].mandatory_contribution;
      this.pensionFundData[index].savedDataInsurance = this.pensionFundData[index].insurance;
      this.pensionFundData[index].savedDataCommisions = this.pensionFundData[index].commissions;
    },

    cancelEditPension(index) {
      this.pensionFundData[index].mandatory_contribution = this.pensionFundData[index].savedDataContribution;
      this.pensionFundData[index].insurance = this.pensionFundData[index].savedDataInsurance;
      this.pensionFundData[index].commissions = this.pensionFundData[index].savedDataCommisions;
      this.pensionFundData[index].editing = false;
    },

    async savePension(pension, index) {
      const pensionArray = this.pensionFundData;
      try {
        if (pensionArray[index].mandatory_contribution !== pensionArray[index].savedDataContribution
        || pensionArray[index].insurance !== pensionArray[index].savedDataInsurance
        || pensionArray[index].commissions !== pensionArray[index].savedDataCommisions
        ) {
          const resultValidationPension = await this.$refs.validatePensionFundData.validate();
          if (resultValidationPension) {
            const response = await this.showConfirmSwal();
            if (response.isConfirmed) {
              const data = await SettingsService.saveChangePensionFund({
                id: pension.id,
                type: pension.type,
                name: pension.name,
                mandatory_contribution: pension.mandatory_contribution,
                insurance: pension.insurance,
                commissions: pension.commissions,
                created_by: this.currentUser.user_id,
              });

              if (data.status === 200) {
                await this.getPensionFundSetting();
                this.showGenericSuccessSwal({});
              }
            }
          }
        } else {
          this.showWarningSwal(
            'Invalid Change',
            'The entered value is the same as the old one',
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    openListTrackingPensionFund(pension) {
      this.idToSend = pension.name;
      this.openListTrackingPensionFundOn = true;
    },

    closeListTrackingPensionFund() {
      this.openListTrackingPensionFundOn = false;
    },

  },

};
</script>

<style scoped>
.lds-ring {
    animation: lds-ring 0.9s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.active-border {
    border: 1px solid rgb(130, 189, 233);
    border-radius: 0.357rem;
}

.title {
    color:#8B869A;
    font-family: sans-serif;
    font-size: 17px;
}

.color-disabled {
    color: #a2a9b3;
}

.subtitle {
    color: #5e5873;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: bold;

}

.margin-tramo , .margin-pension{
   margin-top: 10.5px;

}

.space {
    width: 357px;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 769px) {
  /* Estilos para dispositivos con ancho menor a 575px */
    .modal-subtitle{
        display: none;
    }

    .btn-sm-style{
        width: 31.5px 37.28px;
        padding: 6.75px;
        margin-top: 4px;
    }
  }

@media (max-width: 575px) {
  /* Estilos para dispositivos con ancho menor a 575px */
    .margin-xs{
        padding-top: 20px;
    }

    .btn-sm-style{
        width: 37.84px 37.28px;
        padding: 9.65px 9.93px;
        margin-top: 4px;
    }
  }

</style>
