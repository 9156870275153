<template>
  <b-modal
    v-model="onControl"
    header-bg-variant="primary"
    title-class="h3 text-white font-weight-bolder"
    size="xmd"
    title="Tracking"
    modal-class="modal-primary"
    hide-footer
    scrollable
    @hidden="close"
  >
    <filter-slot
      :no-visible-principal-filter="true"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :filter="[]"
      :filter-principal="{}"
      @reload="$refs['refTrackingTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="refTrackingTable"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchSettingsPensionFund"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #head(name)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(type)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>

          <template #head(mandatory_contribution)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>

          <template #head(insurance)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>

          <template #head(commissions)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(created_by)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(updated_by)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>

          <template #cell(name)="data">
            <td
              class="align-middle text-center d-flex justify-content-center align-items-center mt-0"
            >
              {{ data.item.name }}
            </td>
          </template>

          <template #cell(type)="data">
            <td
              class="align-middle text-center d-flex justify-content-center align-items-center mt-0"
            >
              {{ data.item.type }}
            </td>
          </template>

          <template #cell(mandatory_contribution)="data">
            <td
              class="align-middle text-center d-flex justify-content-center align-items-center mt-0"
            >
              {{ data.item.mandatory_contribution }} <span class="percentage"> %</span>
            </td>
          </template>
          <template #cell(insurance)="data">
            <td class="align-middle text-center d-flex justify-content-center  align-items-center mt-0">
              {{ data.item.insurance }} <span class="percentage"> %</span>
            </td>
          </template>
          <template #cell(commissions)="data">
            <td class="align-middle text-center d-flex justify-content-center  align-items-center mt-0">
              {{ data.item.commissions }} <span class="percentage"> %</span>
            </td>
          </template>

          <template
            #cell(created_by)="data"
            class="text-center"
          >
            <td class="align-middle text-center d-flex justify-content-center  align-items-center m-0 p-0 mt-xxl-2">
              {{ data.item.created_by }} <br> {{ data.item.created_at | myGlobalWithHour }}
            </td>

          </template>

          <template #cell(updated_by)="data">
            <div
              class="align-middle text-center d-flex justify-content-center  align-items-center m-0 p-0 mt-xxl-2"
            >
              {{ data.item.updated_by }} <br> {{ data.item.updated_at | myGlobalWithHour }}
            </div>
          </template>

        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>

import PensionSettingService from '@/views/commons/setting/service/payment-setting.service';

export default {
  props: {
    idPension: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: 'name',
      sortDesc: true,
      searchInput: '',
      fields: [
        {
          key: 'name',
          sortable: false,
          label: 'Name',
          class: 'px-1 pt-1 pb-0',
        },
        {
          key: 'type',
          sortable: false,
          label: 'Type',
          class: 'px-0 pt-1 pb-0',
        },
        {
          key: 'mandatory_contribution',
          sortable: false,
          label: 'Mandatory Contribution',
          class: 'px-0 pt-1 pb-0',
        },
        {
          key: 'insurance',
          sortable: false,
          label: 'Insurance',
          class: 'px-0 pt-1 pb-0',
        },
        {
          key: 'commissions',
          sortable: false,
          label: 'Commissions',
          class: 'px-0 pt-1 pb-0',
        },
        {
          key: 'created_by',
          sortable: false,
          label: 'Created by',
          class: 'px-0 pt-1 pb-0',
        },
        {
          key: 'updated_by',
          sortable: false,
          label: 'Updated by',
          class: 'px-0 py-1 pb-0',
        },
      ],
      trackingItems: [],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },

    };
  },

  async created() {
    this.onControl = true;
    this.addPreloader();
    this.removePreloader();
  },

  methods: {
    close() {
      this.$emit('close');
    },

    async searchSettingsPensionFund(ctx) {
      const response = await PensionSettingService.searchSettingPensionFund({
        pension: this.idPension,
        orderby: 'created_at',
        page: ctx.currentPage,
        order: ctx.sortDesc ? 'desc' : 'asc',
        perPage: ctx.perPage,
      });
      this.trackingItems = response.data.data;
      this.startPage = response.data.from;
      this.paginate.currentPage = response.data.current_page;
      this.paginate.perPage = response.data.per_page;
      this.totalRows = response.data.total;
      this.toPage = response.data.to;
      return this.trackingItems || [];
    },

  },

};
</script>

<style scoped>
 .dark-layout .table td > td{
  border-color: transparent !important;
  padding-right: 0;
  padding-left: 0;
  border: none;
}

.style_created{
  background-color: red;
  padding: 0px !important;
}
.table td>td {
    border-top: none;
    /* width: 50px; */
}

.table td {
  padding-left: 2px;
  padding-right: 2px;
}
.percentage{
  margin-left: 2px;
}

</style>
