/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class ServicePayment {
  async getPaymentSetting() {
    try {
      const data = await amgApi.post('management/settings/get-payment-settings');
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getByCompaniesPaymentSetting() {
    try {
      const data = await amgApi.post('management/settings/get-by-companies-payment-settings');
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveChangeContribution(params) {
    try {
      const data = await amgApi.post('management/settings/save-change-contribution', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveChangeContributionByCompanie(params) {
    try {
      const data = await amgApi.post('management/settings/save-change-contribution-by-companie', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchSettingContribution(params) {
    try {
      const data = await amgApi.post('management/settings/search-setting-contribution', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchSettingContributionByCompanie(params) {
    try {
      const data = await amgApi.post('management/settings/search-setting-contribution-by-companie', params);
      return data;
    } catch (error) {
      throw error;
    }
  }


  async getSettingFifthCategory() {
    try {
      const data = await amgApi.post('management/settings/get-setting-fifth-category');
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveChangeCategory(params) {
    try {
      const data = await amgApi.post('management/settings/save-change-category', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchSettingFifthContribution(params) {
    try {
      const data = await amgApi.post('management/settings/search-setting-fifth-category', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPensionFundSetting() {
    try {
      const data = await amgApi.post('management/settings/get-pension-fund-setting');
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveChangePensionFund(params) {
    try {
      const data = await amgApi.post('management/settings/save-change-pension-fund', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchSettingPensionFund(params) {
    try {
      const data = await amgApi.post('management/settings/search-setting-pension-fund', params);
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new ServicePayment();
