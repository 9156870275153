<template>
  <div class="mt-2 ml-2">
    <b-tabs
      lazy
      active-tab-class="p-0 "
      pills
      nav-class="mb-0"
      active-nav-item-class="bg-info box-shadow-info border-info info"
    >
      <b-tab :title-link-class="[bgTabsNavs, 'sub-tab px-3']">
        <template #title>
          By Companies
          <span class="ml-1">
            <feather-icon
              icon
              badge-classes="badge-danger badge-glow"
            />
          </span>
        </template>
        <ByPaymentSetting />
      </b-tab>
      <b-tab :title-link-class="[bgTabsNavs, 'sub-tab px-3']">
        <template #title>
          All Companies
          <span class="ml-1">
            <feather-icon
              icon
              badge-classes="badge-danger badge-glow"
            />
          </span>
        </template>
        <all-payment-setting />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

import AllPaymentSetting from '@/views/commons/setting/components/payment-setting-modules/AllPaymentSetting.vue';
import ByPaymentSetting from "@/views/commons/setting/components/payment-setting-modules/ByPaymentSetting.vue";

export default {
  components: {
    AllPaymentSetting,
    ByPaymentSetting,
  },

};
</script>
