<template>
    <b-modal
      v-model="onControl"
      header-bg-variant="primary"
      title-class="h3 text-white font-weight-bolder"
      size="xmd"
      :title="titleModal"
      modal-class="modal-primary"
      hide-footer
      scrollable
      @hidden="close"
    >
      <filter-slot
        :no-visible-principal-filter="true"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :filter="[]"
        :filter-principal="{}"
        @reload="$refs['refTrackingTable'].refresh()"
      >
        <template #table>
          <b-table
            ref="refTrackingTable"
            no-border-collapse
            class="position-relative"
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="50vh"
            primary-key="id"
            responsive="sm"
            :items="searchSettingsContribution"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :filter="searchInput"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
  
            <template #head(name)="data">
              <div class="text-center ml-2">
                {{ data.label }}
              </div>
            </template>
            <template #head(value)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #head(created_by)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #head(updated_by)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
  
            <template #cell(name)="data">
              <div class="align-middle text-center d-flex justify-content-center   mt-1">
                {{ data.item.name }}
              </div>
  
            </template>
  
            <template #cell(value)="data">
              <div class="align-middle text-center d-flex justify-content-center  mt-1">
                {{ data.item.value }}
                {{ data.item.type == 'percentage' ? '%' : '' }}
              </div>
  
            </template>
  
            <template
              #cell(created_by)="data"
              class="text-center"
            >
              <td class="align-middle text-center d-flex justify-content-center  align-items-center m-0 p-0">
                {{ data.item.created_by }} <br> {{ data.item.created_at | myGlobalWithHour }}
              </td>
              <!-- {{ data.item.created_by }} <br> {{ data.item.created_at | myGlobalWithHour }} -->
            </template>
  
            <template #cell(updated_by)="data">
              <td class="align-middle text-center d-flex justify-content-center  align-items-center m-0 p-0 ">
                {{ data.item.update_by }} <br> {{ data.item.updated_at | myGlobalWithHour }}
              </td>
  
            </template>
  
          </b-table>
        </template>
      </filter-slot>
    </b-modal>
  </template>
  
  <script>
  
  import ContributionSettingService from '@/views/commons/setting/service/payment-setting.service';
  
  export default {
    props: {
    idCompanie:{
        type: Number,
        default: null,
    },
    slugCompanie:{
        type:String,
        default:''
    },
    nameCompanie:{
        type:String,
        default:''
    }
    },
  
    data() {
      return {
        onControl: false,
        startPage: 0,
        toPage: 0,
        isBusy: false,
        sortBy: 'name',
        sortDesc: true,
        searchInput: '',
        titleModal:'',
        fields: [
          {
            key: 'name',
            sortable: false,
            label: 'Name',
          },
          {
            key: 'value',
            sortable: false,
            label: ' New Value',
          },
          {
            key: 'created_by',
            sortable: false,
            label: 'Created by',
          },
          {
            key: 'updated_by',
            sortable: false,
            label: 'Updated by',
          },
        ],
        trackingItems: [],
        totalRows: 1,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
  
      };
    },
  
    async created() {
      this.onControl = true;
      this.addPreloader();
      this.removePreloader();
    },
  
    methods: {
      close() {
        this.$emit('close');
      },
  
      async searchSettingsContribution(ctx) {
        const response = await ContributionSettingService.searchSettingContributionByCompanie({
          slug: this.slugCompanie,
          companie_id : this.idCompanie,
          orderby: 'created_at',
          page: ctx.currentPage,
          order: ctx.sortDesc ? 'desc' : 'asc',
          perPage: ctx.perPage,
        });

        this.titleModal = 'Tracking - ' + this.nameCompanie;


  
        this.trackingItems = response.data.data;

        this.startPage = response.data.from;
        this.paginate.currentPage = response.data.current_page;
        this.paginate.perPage = response.data.per_page;
        this.totalRows = response.data.total;
        this.toPage = response.data.to;
        return this.trackingItems || [];
      },
  
    },
  
  };
  </script>
  <style >
   .table td>td {
      border-top: none;
  }
  
  .table td {
    padding-left: 2px;
    padding-right: 2px;
  }
  </style>
  