<template>
  <div class="border-3 box-shadow-3 rounded-bottom">
    <div class="px-1 px-sm-0">
      <b-card>
        <b-row>
          <b-card-title class="mr-2 title">
            CONTRIBUTIONS :
          </b-card-title>
          <div class="ml-2">
            <feather-icon
              class="cursor-pointer"
              :class="isBusy ? 'lds-ring' : ''"
              icon="RefreshCcwIcon"
              size="18"
              @click="reloadPage"
            />
          </div>
        </b-row>
  
        <validation-observer ref="validateContributionData">
  
          <b-row class="modal-subtitle mb-1">
            <b-col
              md="4"
              xxl="1"
            />
  
            <b-col
              md="8"
              xxl="9"
            >
              <b-row>
                <b-col
                  md="6"
                  class="font-weight-normal text-center subtitle"
                >
                  <span>BONIFICATION SALARY</span>
                </b-col>
  
                <b-col
                  md="6"
                  class="font-weight-normal text-center subtitle"
                >
                  <span>BASE SALARY</span>
                </b-col>
              </b-row>
            </b-col>
  
            <b-col
              md="2"
              xxl="2"
            />
  
          </b-row> 
  
          <b-row>
            <b-col
             v-for="(contribution, index) in contributionData"
              :key="index"
              md="12"
              class="mt-1"
            >
              <b-row>
                <b-col
                  xxl="1"
                  md="4"
                  sm="12"
                  xs="12"
                >
                  <b-form-group  label-class="font-bureau-style font-weight-normal color-gray-input-sm margin-form-group">
                    <template
                      #label
                    >
                      <div class="margin-tramo">
                        <feather-icon
                          icon="SquareIcon"
                          style="margin-right: 0.5rem; fill: #EFA047"
                          class="text-light"
                        />
                        <span > {{ contribution.business_name.toUpperCase() }} </span>
                      </div>
                    </template>
  
                  </b-form-group>
                </b-col>
  
                <b-col
                  xxl="9"
                  md="8"
                  sm="8"
                  xs="12"
  
                >
                  <b-row>
                    <b-col
                      md="6"
                      sm="6"
                      xs="12"
                      v-for="(item2, index2) in contribution.dataValue" :key="index2"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="contribution.name + index2"
                        :rules="contribution.type == 'percentage' ? 'validate-percentage-min|validate-percentage-max' :'validate-percentage-min'"
                      >
                      <b-skeleton
                        v-if="!finishedLoading"
                        class="space"
                        type="input"
                      />
                        <b-form-group
                          class="mb-0"
                          :class="contribution.editing ? 'active-border' : ''"
                        >
  
                          <!-- <b-skeleton
                            v-if="!finishedLoading"
                            class="space"
                            type="input"
                          /> -->
  
                          <b-input-group
                            v-if="finishedLoading"
                            prepend="%"
                          >
                           
                          <money
                            v-model="item2.value"
                            v-bind="vMoneyPercentage"
                            :class="contribution.editing ? 'color-active' : 'color-disabled'"
                            class="form-control input-form"
                            :disabled="!contribution.editing"
                            @input="changeValue(contribution, item2.slug)"
                            />
  
                     <b-input-group-append
                      v-if="contribution.editing"
                      class="border-right"
                    >
                    <b-button
                      v-if="contribution.editing"
                      class="btn-icon border btn-sm-style"
                      variant="outline-primary"
                      @click="saveContribution(contribution, index)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top="'Save'"
                        icon="SaveIcon"
                        size="16"
                        class="text-primary cursor-pointer"
                      /></b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <template >
                        <b-button
                      v-if="!contribution.editing"
                      class="btn-icon border btn-sm-style"
                      variant="outline-warning"
                      @click="editContribution(contribution, index)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top="'Edit'"
                        icon="Edit3Icon"
                        size="16"
                        class="text-warning cursor-pointer"
                      /></b-button>
  
                      </template>
                      <b-button
                        v-if="contribution.editing"
                        variant="outline-danger"
                        class="btn-icon"
                        @click="cancelEditContribution(contribution, index)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="cursor-pointer"
                        />
                      </b-button>
                    </b-input-group-append>
                    <b-input-group-append class="cursor-pointer">
                      <b-input-group-text
                        @click="
                          openListTrackingCedContribution(
                            contribution,item2.slug
                          )
                        "
                      >
                        <feather-icon
                          icon="ListIcon"
                          class="text-primary"
                        />
                      </b-input-group-text>
                    </b-input-group-append>
  
  
                          </b-input-group>
                        </b-form-group>
                        <span
                          v-if="errors[0]"
                          class="text-danger"
                        >{{ errors[0] }} </span>
                      </validation-provider>
  
                    </b-col>
                  </b-row>
                </b-col>
           
              </b-row>
  
            </b-col>
  
          </b-row>
  
         
  
        </validation-observer>
  
        <ListTrackingContribution
          v-if="openListTrackingContributionOn"
          :idCompanie="idToSend"
          :slugCompanie="slugToSend"
          :nameCompanie="nameToSend"
          @canceledChange="reloadPage"
          @close="closeListTrackingContribution"
        />
  
      </b-card>
    </div>
  </div>
</template>

<script>
import ListTrackingContribution from '@/views/commons/setting/components/modals/ListTrackingContributionByCompanie.vue';
import SettingsService from '@/views/commons/setting/service/payment-setting.service';
import { mapGetters } from 'vuex';

export default {
components: {
    ListTrackingContribution,
  },
  data() {                                     
      return {
      finishedLoading: false,
      idToSend: '',
      slugToSend:'',
      nameToSend:'',
      contributionData: [],
      isBusy: false,
      vMoney: {
        decimal: '.',
        thousand: ',',
        precision: 2,
        min: 0.0,
        maxlength: 10,
        prefix: 'S/.',
      },

      vMoneyDollars: {
        decimal: '.',
        thousand: ',',
        precision: 2,
        min: 0.0,
        maxlength: 10,
      },
      vMoneyPercentage: {
        decimal: '.',
        thousand: ',',
        precision: 2,
        min: 0.0,
        max: 100.00,
        maxlength: 5,
      },
      openListTrackingContributionOn: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

  },

  async created() {
    this.getByCompaniesContributionData(),
    this.finishedLoading = true;
  },

  methods: {

    
      changeValue(contribution,tipo) {
      switch (tipo) {
        case 'SEP':
          contribution.dataValue[0].value = 100 - contribution.dataValue[1].value;
          break;

        case 'BEP':
        contribution.dataValue[1].value= 100 -  contribution.dataValue[0].value;
          break;
      }
    },


  async getByCompaniesContributionData() {
      try {
        let fake = [];
        this.contributionData = [];
        this.isBusy = true;
        const {data,status} = await SettingsService.getByCompaniesPaymentSetting();
        

        if (status === 200) {
         
          data.forEach(contribution => {
            contribution.editing = false;
            contribution.savedDataBep = 0;
            contribution.savedDataSep = 0;
            contribution.value = parseFloat(contribution.value);
          });
          this.contributionData = data;
          setTimeout(() => {
            this.isBusy = false;
          }, 900);
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
        this.isBusy = false;
      }
    },
   async saveContribution(contribution, index){

      try{

          if(contribution.dataValue[0].value !== contribution.savedDataBep || contribution.dataValue[1].value !== contribution.savedDataSep ){
              const resultValidation = await this.$refs.validateContributionData.validate();
              let valid = contribution.dataValue.some((x)=>Number(x.value)  === 0);
               
              if (resultValidation && !valid) {

                  const response = await this.showConfirmSwal();
                  if (response.isConfirmed) {

                  const data = await SettingsService.saveChangeContributionByCompanie({
                  ...contribution,
                  created_by: this.currentUser.user_id,
                  });

                  await this.getByCompaniesContributionData();
                  this.showGenericSuccessSwal({});
                  
                  }
               }
          }   else {
          this.showWarningSwal(
            'Invalid Change',
            'The entered value is the same as the old one',
          );
        }  

      }catch (error) {
        this.showErrorSwal(error);
      } 
    },

    editContribution(contribution,index){
      contribution.savedDataBep = contribution.dataValue[0].value;
      contribution.savedDataSep = contribution.dataValue[1].value;
      contribution.editing = true;
    },
    cancelEditContribution(contribution,index){
      contribution.dataValue[0].value = contribution.savedDataBep;
      contribution.dataValue[1].value = contribution.savedDataSep;
      contribution.editing = false;
    },

    closeListTrackingContribution() {
      this.openListTrackingContributionOn = false;
    },
    openListTrackingCedContribution(contribution,slug){
      this.idToSend = contribution.companie_id;
      this.slugToSend = slug;
      this.nameToSend = contribution.business_name;
      this.openListTrackingContributionOn = true;
    },
    async reloadPage() {
      await this.getByCompaniesContributionData();
      
  },
  },


}
</script>

<style scoped>

.lds-ring {
    animation: lds-ring 0.9s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.active-border {
    border: 1px solid rgb(130, 189, 233);
    border-radius: 0.357rem;
}

.title {
    color:#8B869A;
    font-family: sans-serif;
    font-size: 17px;
}

.color-disabled {
    color: #a2a9b3;
}

.subtitle {
    color: #5e5873;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: bold;

}

.margin-tramo , .margin-pension{
   margin-top: 10.5px;

}

.space {
    width: 357px;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>